import { CloudIcon } from '@heroicons/react/24/outline';

export function Loading({ w = 'full', h = 60 }) {
  {
    /* <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-12 w-12 animate-pulse text-blue-500 place-self-center"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"
            />
          </svg> */
  }
  return (
    <div className={`flex w-${w} justify-center`}>
      <div className={`h-${h} flex flex-col justify-center`}>
        <CloudIcon className="h-12 w-12 animate-bounce text-sky-600" />
      </div>
    </div>
  );
}
