import { XCircleIcon } from '@heroicons/react/24/outline';
import { classNames } from 'utils/classNames';
import { CardFooter } from './CardFooter';

export function CardContainer({ columns, data, children }) {
  return (
    <section className="text-gray-600 body-font overflow-hidden h-full">
      <div className="container mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6">{children}</div>
      </div>
    </section>
  );
}
export function TextBox({ classNames, cardLabel, text }) {
  return (
    <div className="">
      {cardLabel && cardLabel + ': '}
      <span className={'text-base'}>{text}</span>
    </div>
  );
}

export function Card({
  props,
  children,
  statusFunction = null,
  handleClick = null,
  deleteClick = null,
  dragableStyle = null,
  dragableRef = null,
  className = null,
  ...rest
}) {
  let status = statusFunction;

  return (
    <div
      className={classNames(
        // 'aspect-[1.618034/2]',
        'cursor-pointer border m-2 hover:border-gray-400 rounded-md overflow-hidden bg-gray-50 bg-opacity-30 shadow-md transition duration-100 relative',
        className
      )}
      style={dragableStyle}
      ref={dragableRef}
      {...rest}
      onClick={() => {handleClick && handleClick(props);}}
    >
      {deleteClick ? (
        <XCircleIcon
          className="ml-2 h-5 w-5 float-right cursor-pointer hover:fill-current hover:text-red-600 absolute right-0"
          aria-hidden="true"
          onClickCapture={() => deleteClick(props)}
        />
      ) : (
        ''
      )}
      <div className="dark:bg-gray-800 flex flex-col h-full w-full">
        <div className="flex flex-col flex-grow my-2">
          {children &&
            children.map((child, i) => (
              <div className="text-left px-2 py-1 break-words" key={i}>
                {child}
              </div>
            ))}
        </div>
        {status && <CardFooter status={status} />}
      </div>
    </div>
  );
}
