import { useState, useEffect } from 'react';
import { useSystemContext } from './context';
import { ruleService } from 'services/rule.service';
import { subscriptionService } from 'services/subscription.service';
import { accountService } from 'services';
import { Modal } from 'components/Modules/Modal';
import { ChartContainer } from './modal/ChartContainer';
import { AddEditRule } from './modal/AddEditRule';
import { ToggleSwitch } from 'components/Modules/ToggleSwitch';

export function SensorModal() {
  const { sensor, selected, setSelected } = useSystemContext();
  const [rules, setRules] = useState(null);
  const [subscriptions, setSubscriptions] = useState({ email: false, sms: false });
  const account = accountService.userValue;

  useEffect(() => {
    if (sensor) {
      ruleService.getRules(sensor.id).then((x) => {
        setRules(x);
      });

      

      subscriptionService.getById(sensor.id).then((x) => {
        console.log("FOUND SUBSCRIPTION")
        let email = false;
        let sms = false;

        const first = x[0]
        email = first?.email ? first?.email : false;
        sms = first?.sms ? first?.sms : false;

        setSubscriptions({ email, sms });
      });
    }
  }, [sensor]);

  function updateSubscription({ email, sms }) {

    if (sms && !account.phone) {
      alert('Du må legge til mobilnummer for å kunne abonnere på sms notifikasjoner');
    } else {
      setSubscriptions({ email, sms });
      subscriptionService.subscribeTo(sensor.id, email, sms);
    }
  }

  return (
    <Modal
      isOpen={selected && sensor ? true : false}
      setIsOpen={setSelected}
      title={`${sensor?.tag}`}
      size="xl"
    >
      <div className="flex flex-col space-y-2">
        <div className="flex-grow border-b-2">
          <ChartContainer />
        </div>
        <div className="flex flex-col md:flex-row justify-between md:h-20 md:space-x-5 space-y-2 md:space-y-0">
          <div className=" font-semibold flex flex-col space-y-1">
            <div className="flex">
              <div>Alarmer</div>
              <div>
                {rules && rules.length > 0 && <AddEditRule sensor={sensor} rules={rules} setRules={setRules} />}
              </div>
            </div>
            <div className="flex bg-white space-x-4">
              {rules &&
                rules.map((rule, idx) => (
                  <AddEditRule key={idx} sensor={sensor} rule={rule} rules={rules} setRules={setRules} />
                ))}
              {rules && rules.length === 0 && <AddEditRule sensor={sensor} rules={rules} setRules={setRules} />}
            </div>
          </div>
          <div className="  font-semibold">
            <div>Notifikasjoner</div>
            <div className="flex space-x-2">
              <ToggleSwitch
                initialValue={subscriptions?.email}
                onClick={(val) => updateSubscription({ email: val, sms: subscriptions.sms })}
                title="Epost"
              />
              <ToggleSwitch
                initialValue={subscriptions?.sms}
                onClick={(val) => updateSubscription({ email: subscriptions.email, sms: val })}
                title="Sms"
                disabled={!account.phone}
              />
              {/* <div className="flex flex-col">
                
                <Switch
                  checked={subscriptions.email}
                  onChange={() => updateSubscription({ email: !subscriptions.email, sms: subscriptions.sms })}
                >
                  <span className="block bg-white rounded-full shadow p-1 h-8 w-14">
                    <span
                      className={`block h-full w-1/2 rounded-full transition duration-300 ease-in-out transform ${
                        subscriptions.email ? 'bg-green-500 translate-x-full' : 'bg-red-500'
                      }`}
                    ></span>
                  </span>
                </Switch>
                <p className="text-xs uppercase text-center">Epost</p>
              </div>
              <div className="flex flex-col">
                <Switch
                  checked={subscriptions.sms}
                  onChange={() => updateSubscription({ email: subscriptions.email, sms: !subscriptions.sms })}
                >
                  <span className="block bg-white rounded-full shadow p-1 h-8 w-14">
                    <span
                      className={`block h-full w-1/2 rounded-full transition duration-300 ease-in-out transform ${
                        subscriptions.sms ? 'bg-green-500 translate-x-full' : 'bg-red-500'
                      }`}
                    ></span>
                  </span>
                </Switch>
                <p className="text-xs uppercase text-center">SMS</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
