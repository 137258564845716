import { Fragment } from 'react';
import { Dialog, Transition, TransitionChild, TransitionChildi } from '@headlessui/react';

export function Modal({ title, children, className, isOpen, setIsOpen, size }) {
  return (
    <Dialog open={isOpen} as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={() => setIsOpen(false)}>
      <div className="flex items-center justify-center min-h-screen">
        {/* This element is to trick the browser into centering the modal contents. */}
        <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
        </span>
        <div
          className={'inline-block w-11/12 lg:w-9/12 xl:w-7/12 max-w p-6 pt-4 my-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl'}
        >
          <Dialog.Title className="font-bold text-2xl flex justify-between mb-2">
            <div>{title}</div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 hover:text-red-500 cursor-pointer mt-1"
                onClick={() => setIsOpen(false)}
                fill="none"
                viewBox="0 0 20 20"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </Dialog.Title>
          {children}
        </div>
      </div>
    </Dialog>
  );
}
