import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { accountService } from 'services';
import toast from 'react-hot-toast';
import { hasAccess } from 'utils';
import { SubmitButton, LinkButton } from 'components/Elements/Button';

export function Login() {
  const navigate = useNavigate();

  const initialValues = {
    username: '',
    password: '',
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().email('Ugyldig e-postadresse').required('Skriv inn e-post'),
    password: Yup.string().required('Skriv inn passord'),
  });

  function onSubmit({ username, password }, { setSubmitting }) {
    setSubmitting(true);
    accountService
      .login(username, password)
      .then(user => {
        //const { from } = location.state || { from: { pathname: "/" } };
        if (user) navigate('/dashbord');
        else toast.error('Innlogging feilet. Prøv igjen senere.');
      })
      .catch((err) => {
        setSubmitting(false);
        toast.error('Failed to authenticate user');
      });
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <p className="text-5xl mb-6 leading-none">Logg inn</p>
          <div className="card-body">
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2 ml-px">E-post</label>
              <Field
                name="username"
                type="text"
                className={
                  'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' +
                  (errors.username && touched.username ? ' border-red-600' : '')
                }
              />
              {/* <ErrorMessage name="email" component="div" className="text-red-500" /> */}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2 ml-px">Passord</label>
              <Field
                name="password"
                type="password"
                className={
                  'shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' +
                  (errors.password && touched.password ? ' border-red-600' : '')
                }
              />
              {/* <ErrorMessage name="password" component="div" className="text-red-500" /> */}
            </div>
            <div className="mt-8 block xs:flex">
              <div className="block xs:flex">
                <SubmitButton className="w-26">
                  Logg inn
                </SubmitButton>
                <LinkButton to="/" className="w-26 xs:ml-3 mt-2 xs:mt-0">
                  Avbryt
                </LinkButton>
              </div>
              <Link to="../forgot-password" className="flex mt-2 xs:mt-0 ml-auto">
                <p className="my-auto text-blue-500 hover:text-blue-800 hover:underline">Glemt&nbsp;passord?</p>
              </Link>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
