import config from 'config';
import { fetchWrapper } from 'utils';

const baseUrl = `${config.deviceApiUrl}`;

export const deviceService = {
  getAll,
  getById,
  delete: _delete,
  updateSensor,
  updateDevice,
  createTunnel,
  getTunnel,
  getInterfaces,
  getStatus
};

function getInterfaces() {
  return fetchWrapper.get(`${baseUrl}/interfaces`);
}

function getTunnel(id) {
  return fetchWrapper.get(`${baseUrl}/${id}/tunnel`);
}

function createTunnel(deviceId) {
  return fetchWrapper.post(`${baseUrl}/tunnel`, { deviceId });
}

function getAll() {
  return fetchWrapper.get(`${baseUrl}/devices`);
}

function updateSensor(id, params) {
  return fetchWrapper.patch(`${baseUrl}/sensors/${id}`, params);
}

function updateDevice(id, params) {
  return fetchWrapper.put(`${baseUrl}/devices/${id}`, params).then((device) => {
    return device;
  });
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/devices/${id}`);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/devices/${id}`).then((x) => {
    // auto logout if the logged in user deleted their own record
    return x;
  });
}

function getStatus(devices) {
  const uniqueDevices = [...new Set(devices)];
  let query = uniqueDevices.map(device => {
    return `devices=${device}&`;
  });
  query = query.toString().replaceAll(',', '').slice(0, -1);
  return fetchWrapper.get(`${baseUrl}/status/?${query}`);
}