import config from 'config';
import { fetchWrapper } from 'utils';

const baseUrl = `${config.deviceApiUrl}/sensors`;

export const sampleService = {
  getById,
  getLive,
  delete: _delete,
};

function getById(sensorId, viewportMinimum, viewportMaximum, order) {
  const query = {
    start: viewportMinimum || new Date(0).getTime(),
    end: viewportMaximum || new Date().getTime(),
    limit: 10000,
    aggregate: order || 'desc',
  };
  return fetchWrapper.get(`${baseUrl}/${sensorId}/samples?${new URLSearchParams(query)}`);
}

function getLive(sensorId) {
  return fetchWrapper.get(`${baseUrl}/${sensorId}/samples`);
  // return fetchWrapper.get(`${baseUrl}/${sensorId}`);
}

// prefixed with underscore because 'delete' is a reserved word in javascript
function _delete(id) {
  return fetchWrapper.delete(`${baseUrl}/?${id}`).then((x) => {
    // auto logout if the logged in user deleted their own record
    return x;
  });
}
