import { useEffect, useState } from 'react';
import { dashboardService } from 'services/dashboard.service';
import { GridController } from './GridController';
import { Widget } from '../components/Widget';
import { Route, Routes, useParams, NavLink } from 'react-router-dom';
import { useDashboardContext } from '../context';
import { customerService } from 'services';
import { accountService } from 'services';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { Loading } from 'components/Modules/Loading';
import { AddDashboard } from './AddDashboard';
import CustomDropdown from 'components/Modules/CustomDropdown';
import React from 'react';

export function MyDashboard({ displayedPath }){
  return (
    <Routes>
      <Route index path={'/'} element={<DisplayDashboards />} />
      <Route path={':dashboardId'} element={<CustomDashboard displayedPath={displayedPath} />} />
      <Route path={':dashboardId/preview'} element={<CustomDashboard displayedPath={displayedPath} preview />} />
    </Routes>
  );
}

export function DisplayDashboards() {
  const user = accountService.userValue;
  const [dashboards, setDashboards] = useState(user?.dashboards);

  function removeDashboard(e) {
    dashboardService.remove(e).then(() => setDashboards(dashboards.filter((elem) => elem.id !== e)));
  }

  async function addDashboard() {
    const newDashboard = await dashboardService.add();
    setDashboards([...dashboards, newDashboard]);
  }

  return (
    <Widget>
      <div className="flex -m-3">
        {dashboards.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <div className="flex flex-col items-center m-3">
                <NavLink
                  to={`/dashbord/mine-dashbord/${item.id}`}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <div className="cursor-pointer flex p-3 border-2 rounded border-gray-300 w-min bg-white">
                    <p className="font-semibold text-base">{item.title}</p>
                    <XCircleIcon
                      className="w-4 ml-3 float-right hover:fill-current hover:text-red-600"
                      aria-hidden="true"
                      onClick={() => removeDashboard(item.id)}
                    />
                  </div>   
                </NavLink>
              </div>
            </React.Fragment>
          );
        })}
        <div className="flex flex-col items-center m-3">
          <div className="cursor-pointer flex p-3 border-2 rounded border-gray-300 w-fit h-min bg-white" onClick={addDashboard}>
            <p className="font-semibold text-base">+ Legg til dashbord</p>
            {/* <CustomDropdown name="Legg til">
            <AddDashboard dashboards={dashboards} setDashboards={setDashboards}></AddDashboard>
          </CustomDropdown> */}
          </div>  
        </div>
      </div>
    </Widget>
  );
}

export function CustomDashboard({ displayedPath, preview = false }) {
  const { dashboardId } = useParams();
  const [state, setState] = useState(null);
  const { setPathname } = useDashboardContext();

  useEffect(() => {
    dashboardService.getById(dashboardId).then((x) => {
      setState(x);
    });
  }, [dashboardId]);

  useEffect(() => {
    setPathname([displayedPath, 'Title'].flat());
    return () => {
      setPathname(displayedPath);
    };
  }, [displayedPath, setPathname]);

  return (
    <Widget>
      {state ? (<GridController dashboardId={dashboardId} cards={state && state.cards} columns={6} preview={preview} />) : (<Loading />)}
    </Widget>
  );
}
