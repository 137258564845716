import { Switch } from '@headlessui/react';
import { useEffect, useState } from 'react';

export function ToggleSwitch({ title, onClick, initialValue, disabled }) {
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(initialValue);
  }, [initialValue]);

  return (
    <div className="flex flex-col space-y-1">
      <Switch
        checked={state}
        onChange={() => {
          if (disabled) {
            return alert ('Du må legge til mobilnummer for å kunne abonnere på sms notifikasjoner');
          }
          setState(!state);
          onClick(!state);
        }}
      >
        <span className={`block rounded-full transition duration-300 transform shadow p-0.5 h-7 w-13 my-0.5 ${
          state ? 'bg-sky-600' : 'bg-gray-300'
        } ${
          disabled && 'cursor-not-allowed'
        }`
        }>
          <span
            className={`block h-full w-1/2 rounded-full transition duration-300 bg-white ease-in-out transform ${
              state && 'translate-x-full'
            }`}
          ></span>
        </span>
      </Switch>
      <p className="text-center uppercase text-xs">{title}</p>
    </div>
  );
}
