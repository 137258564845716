import { useEffect, useState } from 'react';

export function ActiveDays({ dayString, setDayString, viewOnly = false }) {
  const [days, setDays] = useState([
    { id: '1', title: 'Man', active: false },
    { id: '2', title: 'Tir', active: false },
    { id: '3', title: 'Ons', active: false },
    { id: '4', title: 'Tor', active: false },
    { id: '5', title: 'Fre', active: false },
    { id: '6', title: 'Lør', active: false },
    { id: '0', title: 'Søn', active: false },
  ]);

  function daysToString(daysArray) {
    return daysArray
      .filter((day) => day.active === true)
      .map((day) => {
        return day.id;
      })
      .join('');
  }

  useEffect(() => {
    const activeArray = Array.from(dayString);
    setDays(
      days => days.map((day) => {
        return {
          ...day,
          active: activeArray.filter((x) => day.id === x)[0] ? true : false,
        };
      })
    );
  }, [dayString]);

  return (
    <div className="flex justify-center">
      {days &&
        days.map((day) => (
          <button
            key={day.title}
            type="button"
            onClick={() => {
              !viewOnly &&
                daysToString(
                  days.map((x) => {
                    return { ...x, active: day.title === x.title ? !x.active : x.active };
                  })
                ) !== '' &&
                setDays(() =>
                  days.map((x) => {
                    return { ...x, active: day.title === x.title ? !x.active : x.active };
                  })
                );
              !viewOnly &&
                daysToString(
                  days.map((x) => {
                    return { ...x, active: day.title === x.title ? !x.active : x.active };
                  })
                ) !== '' &&
                setDayString(() =>
                  daysToString(
                    days.map((x) => {
                      return { ...x, active: day.title === x.title ? !x.active : x.active };
                    })
                  )
                );
            }}
            className={`${viewOnly ? 'text-xs px-1' : 'px-1.5'} border-b border-t border-l py-1 ${
              day.title === 'Man' && 'rounded-l-lg'
            } ${day.title === 'Søn' && 'border-r rounded-r-lg'} ${
              day.active
                ? viewOnly
                  ? 'bg-sky-600 border-sky-700'
                  : 'bg-sky-600 border-sky-700 hover:bg-sky-700 hover:border-sky-700'
                : viewOnly
                  ? ''
                  : 'hover:bg-sky-600 hover:border-sky-700 hover:border-opacity-70'
            }`}
          >
            {day.title}
          </button>
        ))}
    </div>
  );
}
