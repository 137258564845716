import { useState, useEffect, useMemo } from 'react';
import * as React from 'react';
import { deviceService } from 'services';
import Table, { StatusPill, DatePill } from './Table';
import { ContentContainer } from 'components/Containers/ContentContainer';
import { useNavigate } from 'react-router';
import { Loading } from '../Loading';
import { GetGetDeviceStatus } from 'utils/status';

export function DeviceTable({ clickPrefix }) {
  const navigate = useNavigate();
  const [devices, setDevices] = useState(null);

  const deviceColumns = React.useMemo(
    () => [
      {
        Header: 'Tittel',
        accessor: 'title',
        cardLabel: 'tittel',
      },
      {
        Header: 'ID',
        accessor: 'id',
        hideOnCard: true,
      },
      {
        Header: 'Sensorer',
        accessor: 'sensors.length',
        hideOnCard: true,
      },
      {
        Header: 'Sist oppdatert',
        accessor: 'updatedAt',
        Cell: DatePill,
        hideOnCard: true,
      },
      {
        Header: 'Status',
        id: 'status',
        accessor: 'id',
        Cell: StatusPill,
        hideOnCard: true,
      },
    ],
    []
  );

  useEffect(() => {
    deviceService.getAll().then((data) => {
      setDevices(data.sort((a, b) => (a.status < b.status ? -1 : 1)));
    });
  }, []);

  function removeDevice(deviceId) {
    setDevices(
      devices.map((x) => {
        if (x.id === deviceId) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    deviceService.delete(deviceId).then(() => {
      setDevices((devices) => devices.filter((x) => x.id !== deviceId));
    });
  }
  function deviceClick(object) {
    navigate((clickPrefix ? `${clickPrefix}` : '') + `${object.id}`);
  }

  return (
    <ContentContainer>
      <div className="text-center mb-4">
        <h3>Enheter</h3>
        <p>Antall enheter: {devices && devices.length}</p>
      </div>
      {devices ? <Table columns={deviceColumns} statusFunction={GetGetDeviceStatus} data={devices} handleClick={deviceClick} /> : <Loading />}
    </ContentContainer>
  );
}
