import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { accountService, customerService, deviceService } from 'services';


const AppContext = createContext({
  status: null
});

export function AppWrapper({ children }) {
  const user = accountService.userValue;
  const customer = customerService.customerValue;
  const [status, setStatus] = useState(null);

  const statusRetriever = useCallback(async () => {
    if (customer) {
      const devices = [];
      customer.locations.map(location => {
        location.systems.map(system => {
          devices.push(system.deviceId);
        });
      });
      deviceService.getStatus(devices).then((status) => {
        setStatus(status);
      }
      );
    } else {
      const devices = await deviceService.getAll();
      const deviceIds = devices.map(device => device.id);
      deviceService.getStatus(deviceIds).then((status) => {
        setStatus(status);
      });
    }
  }, [customer]);

  useEffect(() => {
    if (user) {
      statusRetriever();
      const statusController = setInterval(() => {
        statusRetriever();
      }, 15000);
  
      return () => {
        clearInterval(statusController);
      };
    }
  }, [user, statusRetriever]);

  const state = useMemo(() => {
    const s = {
      status
    };

    return s;
  }, [status]);

  return (
    <AppContext.Provider value={state}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}