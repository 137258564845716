import config from 'config';
import { fetchWrapper } from 'utils';

const baseUrl = `${config.apiUrl}/system`;

export const systemService = {
  uploadFile,
  removeFile,
  getCanvases,
  saveCanvas,
  removeCanvas,
  getById,
  update,
  addSensorPosition,
  updateSensorPosition,
  removeSensorPosition
};

function uploadFile(systemId, file) {
  let formData = new FormData();
  formData.append('file', file.file);
  return fetchWrapper.postFormData(`${baseUrl}/file/${systemId}`, formData);
}

function removeFile(fileId) {
  return fetchWrapper.delete(`${baseUrl}/file/${fileId}`);
}

function getCanvases() {
  return fetchWrapper.get(`${baseUrl}/canvas`);
}

function saveCanvas(canvas) {
  return fetchWrapper.post(`${baseUrl}/canvas`, canvas);
}

function removeCanvas(id) {
  return fetchWrapper.delete(`${baseUrl}/canvas/${id}`);
}

function getById(id) {
  return fetchWrapper.get(`${baseUrl}/${id}`);
}

function update(id, params) {
  return fetchWrapper.put(`${baseUrl}/${id}`, params);
}

function addSensorPosition(params) {
  return fetchWrapper.post(`${baseUrl}/`, params);
}

function updateSensorPosition(params) {
  return fetchWrapper.put(`${baseUrl}/`, params);
}

function removeSensorPosition(systemId, sensorId) {
  return fetchWrapper.delete(`${baseUrl}/${systemId}/${sensorId}`);
}
