export function ItemSelect({ id, state, resizeEvent }) {
  const points = [
    {
      dir: { left: true, top: true, xResize: true, yResize: true },
      id: 'lt',
      cursor: 'nw-resize',
      x: state.x - state.w / 2,
      y: state.y - state.h / 2,
    },
    {
      dir: { left: false, top: true, xResize: true, yResize: true },
      id: 'rt',
      cursor: 'ne-resize',
      x: state.x + state.w / 2,
      y: state.y - state.h / 2,
    },
    {
      dir: { left: false, top: false, xResize: true, yResize: true },
      id: 'rb',
      cursor: 'se-resize',
      x: state.x + state.w / 2,
      y: state.y + state.h / 2,
    },
    {
      dir: { left: true, top: false, xResize: true, yResize: true },
      id: 'lb',
      cursor: 'sw-resize',
      x: state.x - state.w / 2,
      y: state.y + state.h / 2,
    },
    {
      dir: { left: true, top: false, xResize: true, yResize: false },
      id: 'l',
      cursor: 'w-resize',
      x: state.x - state.w / 2,
      y: state.y,
    },
    {
      dir: { left: false, top: true, xResize: false, yResize: true },
      id: 't',
      cursor: 'n-resize',
      x: state.x,
      y: state.y - state.h / 2,
    },
    {
      dir: { left: false, top: false, xResize: true, yResize: false },
      id: 'r',
      cursor: 'e-resize',
      x: state.x + state.w / 2,
      y: state.y,
    },
    {
      dir: { left: false, top: false, xResize: false, yResize: true },
      id: 'b',
      cursor: 's-resize',
      x: state.x,
      y: state.y + state.h / 2,
    },
    {
      dir: { rotate: true },
      id: 'rotate',
      cursor: 'ew-resize',
      x: state.x,
      y: state.y - state.h / 2 - 50,
    },
  ];

  return (
    <>
      {state &&
        points &&
        points.map((point) => (
          <circle
            key={point.id}
            cx={point.x}
            cy={point.y}
            r={7}
            className={`point ${point.dir}`}
            cursor={point.cursor}
            onPointerDown={(e) => resizeEvent(e, id, point.dir)}
            transform={`rotate(${state.r} ${state.x} ${state.y})`}
            fill={point.id === 'rotate' ? 'white' : '#1dadf5'}
            stroke={point.id === 'rotate' ? '#1dadf5' : 'false'}
            strokeWidth={3}
          />
        ))}
      {state && points && (
        <text
          x={points.filter((point) => point.id === 't')[0].x - 100}
          y={points.filter((point) => point.id === 't')[0].y - 80}
          tabIndex="0"
          pointerEvents="none"
        >
          {`x: ${state.x}, y: ${state.y}, w: ${state.w}, h: ${state.h} r: ${state.r}`}
        </text>
      )}
    </>
  );
}
